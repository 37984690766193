import React from 'react';
import Router, { useRouter } from 'next/router';
import { useAuthentication } from './useAuthentication';
import { session } from '../services/stripe.services';
import { usePaymentsModal } from '../components/PaymentsModal';

const usePayment = ({ layoutRef, setLoadingCard, landingPageLink }) => {
  const router = useRouter();
  const { user } = useAuthentication();
  const paymentsModal = usePaymentsModal();

  return item => {
    if (!user && layoutRef && layoutRef.current) {
      layoutRef.current.showSignUp(
        () => {
          if (item.type === 'Free' || item.price === 0) {
            setLoadingCard(false);
            Router.push(landingPageLink || router.pathname);
            paymentsModal.close();
          } else {
            if (typeof setLoadingCard === 'function') setLoadingCard(item.id);
            session({ price: item.id, category: item.category });
          }
        },
        { plan: true }
      );
    } else if (item.type === 'Free' || item.price === 0) {
      setLoadingCard(false);
      paymentsModal.close();
      Router.push(landingPageLink);
    } else {
      if (typeof setLoadingCard === 'function') setLoadingCard(item.id);
      session({ price: item.id, category: item.category });
    }
  };
};

export default usePayment;
